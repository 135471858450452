.dashboardMain {
  width: 100%;
  min-height: 100dvh;
}

.dashboardHeader {
  width: 100%;
}

.dashboardHeader p {
  font-size: 1.9rem;
  font-weight: bold;
  margin: 0;
  padding: 1% 0;
  padding-left: 2%;
  background-color: #ffffff;
  color: #333842;
  box-shadow: 0px -1px 0px 0px #dcdee5 inset;
}

.menuContaier {
  box-sizing: border-box;
  display: flex;
  width: 95%;
  margin: auto;
  /* height: 50px; */
  background-color: #ffffff;
  padding: 1.1% 2%;
  box-shadow: none;
  box-shadow: 0px -1px 0px 0px #dcdee5 inset;
}
.dashboardMenu {
  color: var(--Grey-900, #333842);
  /* Navigation/Nav Label Active */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  width: 45%;
  display: flex;
  margin: auto;
  height: 3vh;
  gap: 2%;
}

.menuItem {
  margin-top: 1%;
}

.dashboardFilter {
  width: 80%;
  align-items: center;
  height: 5vh;
  display: flex;
  gap: 5.5%;
}

.dashboardFilter button span {
  color: var(--Grey-800, #54575e);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.dashboardMenu p {
  margin: 0;
  height: 100%;
  font-weight: 600;
}

element.style {
  border: none !important;
}
.active {
  text-decoration: underline;
  text-decoration-color: var(--var-primary);
  text-decoration-thickness: 2px;
  text-underline-offset: 3vh;
}
.selectCss {
  color: var(--Grey-800, #54575e);
  border: none;
  outline: none !important;
  box-shadow: none !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 120px;
}

.chartMain{
    width: 100%;
    margin: 0;
}

.chartRow{
    width: 100%;
    display: flex;
    gap: 2%;
    row-gap: 10vh;
}

.chartRowTwo{
    margin-top: 4%;
}

.singleChart{
    width: 48%;
    border-radius: 8px;
    border: solid 5px white;
}



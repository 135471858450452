.container {
    display: flex;
    /* flex-wrap: wrap; */
  }
  .flexItem {
    display: flex;
    align-items: center;
  }
  .grid2 {
    padding: 20px;
    margin-top: 20px;
    background: #ffffff;
    width: fit-content;
    border-radius: 10px;
    height: 560px;
  }
  
  .grid1 {
    padding: 20px;
  }
  .leftTwo p {
    display: flex;
    align-items: center;
  }
  
  .top_cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 166px;
    text-align: center;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 53.5px 28px 53.5px 35px;
    box-shadow: 0px 3px 6px 0px #0000000f;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06),
      0px 0px 1px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 32px;
    cursor:'pointer'
  }
  
  .feedbackMain {
    width: 95%;
    margin: auto;
    padding-top: 2%;
  }
  
  .feedbackCards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3vh;
  }
  
  .rowOne1 {
    width: 100%;
    display: flex;
    gap: 85px;
    flex-wrap: wrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rowOne {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* gap: 85px; */
  }
  
  .singleCard {
    /* width: 30%;
      display: flex;
      box-shadow: 0px 3px 6px 0px #0000000F;
      padding: 1% 1%;
      border-radius: 8px;
      background-color: white; */
  
    display: flex;
    flex-direction: column;
    height: 144px;
    width: 144px;
    text-align: center;
    border-radius: 50%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    border-right: 3px solid #ffd600;
    border-bottom: 3px solid #ffd600;
  }
  
  .left {
    width: auto;
  }
  
  .right {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  
  .left p:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding-top: 2%;
    padding-left: 4%;
    padding-bottom: 2%;
  }
  
  .left p > span {
    font-size: 0.9rem;
  }
  
  .left p:last-child {
    font-size: 0.9rem;
    margin: 0;
    padding-left: 4%;
    display: flex;
    align-items: center;
  }
  
  .left p > img {
    margin-left: 3%;
    height: 15px;
  }
  
  .cardGrowth {
    width: fit-content;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1fae1;
    border-radius: 12px;
    margin-top: 15%;
    gap: 10%;
    padding: 0 0.8rem;
  }
  
  .cardGrowth p {
    margin: 0;
    color: #19ac19;
  }
  
  .cardGrowth img {
    width: 18px;
    height: 18px;
  }
  
  .singleCardTwo {
    width: 22%;
    display: flex;
    box-shadow: 0px 3px 6px 0px #0000000f;
    padding: 18px 12px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
  }
  
  .activeSingleCardTwo {
    border: 1px solid black;
  }
  
  .leftTwo {
    width: 80%;
  }
  
  .rightTwo {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  
  .leftTwo p:first-child {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding-top: 2%;
    padding-left: 4%;
    padding-bottom: 2%;
  }
  
  .leftTwo p > span {
    font-size: 0.8rem;
  }
  
  .leftTwo p:last-child {
    font-size: 0.9rem;
    margin: 0;
    padding: 5px
    /* padding-left: 4%; */
  }
  
  .leftTwo p > img {
    height: 25px;
  }
  
  .cardGrowthTwo {
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e1fae1;
    border-radius: 12px;
    margin-top: 15%;
    gap: 8%;
    padding: 0 0.3rem;
  }
  
  .cardGrowthTwo p {
    margin: 0;
    color: #19ac19;
    font-size: 0.8rem;
  }
  
  .cardGrowthTwo img {
    width: 14px;
    height: 14px;
  }
  
  .chartContainer {
    width: 100%;
    margin-top: 4%;
  }
  
  .redBackground {
    background-color: #ffe5e5;
    color: #da1717;
  }
  
  .selectedCardBorder {
    border: 2px solid black;
    /* Adjust the border style as needed */
  }
  
.tableHeder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.tableHeder p {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: Montserrat;
}

.tableMain {
  width: 100%;
  margin-top: 2%;
}

.tableMain > table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.tableMain > table th,
.tableMain > table td {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 2px solid #f9f1ff;
}

.tableMain > table td {
  text-align: center;
  width: 25%;
  padding: 1.5%;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: white;
}

.tableMain > table th {
  text-align: center;
  font-size: 0.9rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  color: white;
  padding: 2% 0;
  color: black;
}

.tableColOne {
  width: 74%;
}
.tableColTwo {
  width: 26%;
}

.tableAction {
  display: flex;
  gap: 15%;
  padding-left: 4%;
}

.tableAction img:first-child {
  width: 16px;
  height: 16px;
}

.tableAction img:last-child {
  width: 13px;
  height: 16px;
}

.pagination {
  width: 100%;
  padding: 2% 0;
}

.pagination > ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 1%;
  font-family: Montserrat;
  font-weight: 500;
  padding-right: 2%;
}

.pagination > ul > li {
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  width: 20px;
  font-family: Montserrat;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .pagination > ul > li:hover {
    background-color:#333842;
    text-align: center;
    border-radius: 5px;
    width: 1.5%;
    height: 2vh;
  } */

.pagination > ul > li > a {
  width: 25px;
  height: 20px;
}

.activePage {
  background-color: #333842;
  text-align: center;
  border-radius: 5px;
  color: white !important;
  width: 1.5%;
  height: 2vh;
  padding: 5px 10px;
}

/* .pagination > ul > li > a:hover {
    color: white;
    font-size: 15px;
  } */

.pagination > ul > li.active {
  background-color: red;
}

.pagination > ul > li.active > a {
  width: 20px;
  height: 20px;
  color: white;
  background-color: red;
}


.navbarContainer{
    display: flex;
    flex-direction: column;
    gap: 0;
    background-color:#333842;
    ;
}

.navbarMain{
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 3%;
}

.imageContainer{
    width: 13vw;
    height: 3vh;
    padding: 20px, 20px, 20px, 18px      
}
.navbarHeader{
    width: 15.5%;
    display: flex;
    gap: 5vw;
    padding: 10%;
}

.navbarHeaderNExp{
    width: 5%;
    display: flex;
    gap: 5%;
    padding-top: 6%;
}

.navbarHeader img:first-child {
    width: 30vw;
    cursor: pointer;
}

.navbarHeaderNExp img:first-child {
    width: 3vw;
    cursor: pointer;
}

.toggleImage {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 5px;
}

.navbarHeaderNExp img:last-child {
    width: 3vw;
    cursor: pointer;
}

.mainContainer{
    display: flex;
    min-height: 100dvh
}

.menuContainer > *:first-child {
    margin-top: 9%;
}
.menuContainerTwo > *:first-child {
    margin-top: 100%;
}


.menu {
    width: 100%;
    cursor: pointer;
    padding-left: 8%;    

}

  .activeMenuItem {
    background-color: #FFFFFF12;
    border-left: 7px solid #EBCE7A;
  }

.active {
color: yellow;
}

.active a {
    color: white;
    padding: 2% 0;    
}


.menu a{
    height: 2rem;
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    align-items: center;
    width: 100%;
    text-decoration: none;
    font-size: 1rem;
}

.menu img {
    width: 10%;
}

.menu p {
    margin: 0;
}

navbarMain p {
    display: inline-block;
}



.navbarMainNExp {
    width: 5%;
}

.navbarMainNExp > *:first-child {
    margin-top: 0%;
}

.routerPage{
    width: 84.5%;
    background-color: #f9f9fb;
}

.routerPageNExp{
    width: 95%;
    background-color: #f9f9fb;
}


